import axios from "axios";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import "../Static/CSS/home.css";

function Home() {

    return (
        <>
            <section className="home-container">
                <section className="home1 container">
                    <div className="home1-left">
                        <h1 className="welcome-msg">
                            <div className="text1">
                                GET <span className="welcome-msg-code">CODE</span> & <span className="welcome-msg-preview">PREVIEW</span>
                            </div>
                            <div className="text2">
                                AS YOU IMAGINE
                            </div>
                            <div className="develop-button">
                                <Link to="/chat" className="chat-a">
                                    Develop
                                    <span className="material-symbols-outlined develop-arrow">
                                        arrow_right_alt
                                    </span>
                                </Link>

                            </div>
                        </h1>
                    </div>
                    {/* <div className="home1-right">
                        <img src={welcomeImage} alt="image" className="welcome-image"/>
                    </div> */}
                </section>

                <section className="about container" id="about">
                    <h1 className="about-heading">About</h1>
                    <div className="about-desc">
                        <p className="about-desc1">
                            Hi, I'm Darshan, and I’ve built this platform to make website creation easy for everyone, regardless of technical experience. Whether you're a complete beginner or someone with web development knowledge, this tool allows you to create fully functional websites just by describing what you want. Simply provide a prompt, and you'll receive the code along with a live preview of how your website will look.
                        </p>
                        <p className="about-desc2">
                            My goal in developing this platform was to eliminate the technical barriers of web design, so that anyone can create a website quickly and easily. Just share your ideas, and <span className='about-codesip'>{"</codesip>"}</span> will generate everything you need in real time.
                        </p>
                    </div>
                </section>

                <section className="contact container" id="contact">
                    <h1 className="contact-heading">Let's Connect</h1>
                    <div className="contact-con">
                        <Link to="https://www.linkedin.com/in/imdarshannn" target="_blank"><i className="fa-brands fa-linkedin"></i></Link>
                        <Link to="https://x.com/im_darshhh" target="_blank"><i className="fa-brands fa-x-twitter"></i></Link>
                        <Link to="https://github.com/Darshan-SD" target="_blank"><i className="fa-brands fa-github"></i></Link>
                    </div>
                </section>
            </section>
        </>
    );
}

export default Home;
