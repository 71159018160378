import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../Static/CSS/login.css';

function Signup({ setVerificationPageFlag, setSignupUserData, addNotification }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const [confirmPasswordFlag, setConfirmPasswordFlag] = useState(false);
  const [passwordRegexFlag, setPasswordRegexFlag] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  // For regex
  const [uppercase, setUppercase] = useState(false);
  const [lowercase, setLowercase] = useState(false);
  const [number, setNumber] = useState(false);
  const [length, setLength] = useState(false);
  const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{6,}$/;

  const navigate = useNavigate();

  const passwordCheck = (p) => {
    setPassword(p);
    const uppercaseRegex = /(?=.*[A-Z])/;
    const lowercaseRegex = /(?=.*[a-z])/;
    const lengthRegex = /.{6,}/;
    const numberRegex = /(?=.*\d)/;

    setUppercase(uppercaseRegex.test(p) ? true : false);
    setLowercase(lowercaseRegex.test(p) ? true : false);
    setNumber(numberRegex.test(p) ? true : false);
    setLength(lengthRegex.test(p) ? true : false);
    setPasswordRegexFlag(passwordRegex.test(p) ? true : false);
  }

  const checkConfirmPassword = (element) => {
    setConfirmPassword(element.value);
    console.log(element.value)
    if (password === element.value) {
      setConfirmPasswordFlag(true);
      element.style.borderBottom = "solid 0.5px #5cff5c";
      element.style.color = "#5cff5c";
    }
    else {
      setConfirmPasswordFlag(false);
      element.style.borderBottom = "solid 0.5px #ff8080";
      element.style.color = "#ff8080"
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      if (passwordRegexFlag && confirmPasswordFlag) {
        const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/user/verify', {
          name,
          email
        },
          { withCredentials: true });

        if (response.status === 200) {
          addNotification(response.data.message || 'Verification code sent.');
          setVerificationPageFlag(true);
          setSignupUserData({ name: name, email: email, password: password });
          navigate("/verification");
        }
        else {
          addNotification(response.data.message || 'Unable to send the code.', '#652222');
        }
      }
      else {
        addNotification('Fulfill password requirements', '#652222');
      }
    } catch (error) {
      if (error.response) {
        addNotification(error.response.data.message, '#652222');
      } else if (error.request) {
        addNotification('No response from the server', '#652222');
      } else {
        addNotification('Error creating the request', '#652222');
      }
    }
    setLoading(false);
  };

  return (
    <div className="signup-container">
      <form onSubmit={handleSubmit} className="signup-form">
        <h2>sign up</h2>
        <div className="input-group">
          {/* <label htmlFor="name">Name:</label> */}
          <input
            type="text"
            id="name"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
          />
        </div>
        <div className="input-group">
          {/* <label htmlFor="email">Email:</label> */}
          <input
            type="email"
            id="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
        </div>
        <div className="input-group">
          {/* <label htmlFor="password">Password:</label> */}
          <input
            type={passwordVisibility ? "text" : "password"}
            id="password"
            required
            value={password}
            onChange={(e) => passwordCheck(e.target.value)}
            placeholder="Password"
          />
          <span class="material-symbols-outlined password-visibility" onClick={() => {setPasswordVisibility(!passwordVisibility)}}>
            {passwordVisibility ? "visibility" : "visibility_off"}
          </span>
        </div>

        <div className="password-instructions">
          <h4 className="password-heading">
            Your password must contain
          </h4>
          <div className="password-symbols">
            <div className="password-symbol">
              <span className="material-symbols-outlined ps" style={{ opacity: uppercase ? '1' : '0.2', textShadow: uppercase ? '0 0 2px var(--lightYellow)' : 'none' }}>
                uppercase
              </span>
              <span>uppercase</span>
            </div>
            <div className="password-symbol">
              <span className="material-symbols-outlined ps" style={{ opacity: lowercase ? '1' : '0.2', textShadow: lowercase ? '0 0 2px var(--lightYellow)' : 'none' }}>
                lowercase
              </span>
              <span>lowercase</span>
            </div>
            <div className="password-symbol">
              <span className="material-symbols-outlined ps" style={{ opacity: number ? '1' : '0.2', textShadow: number ? '0 0 2px var(--lightYellow)' : 'none' }}>
                123
              </span>
              <span>numbers</span>
            </div>
            <div className="password-symbol">
              <span className="material-symbols-outlined ps ps-length" style={{ opacity: length ? '1' : '0.2', textShadow: length ? '0 0 2px var(--lightYellow)' : 'none' }}>
                counter_6
              </span>
              <span>length</span>
            </div>
          </div>
          {/* <ul className="password-ins-list">
            <li className="password-li"></li>
            <li className="password-li"></li>
            <li className="password-li"></li>
            <li className="password-li"></li>
          </ul> */}
        </div>

        <div className="input-group">
          {/* <label htmlFor="password">Password:</label> */}
          <input
            type="password"
            id="password1"
            required
            value={confirmPassword}
            onChange={(e) => { checkConfirmPassword(e.target) }}
            placeholder="Confirm Password"
          />
        </div>

        <button type="submit" disabled={loading ? true : false}>
          {loading ?
            <span className='material-symbols-outlined loading-icon'>progress_activity</span>
            : "let's go"}
        </button>
        <p className="login-link">
          Already have an account? <Link to="/login">Log in</Link>
        </p>
      </form>
    </div>
  );
}

export default Signup;
