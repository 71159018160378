const Notification = ({ desc, color }) => {
    return (
        <div className="notification" style={{backgroundColor: color}}>
            <div className="notification-desc">
                {desc}
            </div>
        </div>
    )
}

export default Notification;