function MessageBox({ type, text, position, date, dateString }) {
    return (
        <>
            <div className="chat-con" style={position == "left" ? { justifyContent: "flex-start" } : { justifyContent: "flex-end" }}>
                <div className={`chat-message-wrapper ${position == "left" ? "chat-message-wrapper-left" : ""}`}>
                    {text}
                </div>
            </div>
        </>
    )
}

export default MessageBox;