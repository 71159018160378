import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Prism from "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import 'prismjs/components/prism-cshtml';
import MessageBox from './MessageBox';
import '../Static/CSS/chat.css';
import '../Static/CSS/chatcode.css';

function Chat({ userData }) {

    const [userInput, setUserInput] = useState('');
    const [messages, setMessages] = useState([]);
    const [htmlContent, setHtmlContent] = useState("Preview");
    const [codeOpen, setCodeOpen] = useState(false);
    const [chatData, setChatData] = useState({});

    const structureCode = (unstructuredCodeString) => {
        let unstructuredCodeArray = unstructuredCodeString.split('');
        let tagName = "";
        let tagNameFlag = false;
        let tagNameArray = [];
        unstructuredCodeArray.map((letter, index) => {
            if(letter == ">" || (tagName != "" && letter == " ")){
                tagNameFlag = false;
            }
            if(tagNameFlag){
                tagName = tagName + letter;
            }
            if(unstructuredCodeArray[index-1] == "<" && letter != ">" && letter != "/" && letter != "!"){
                tagName = letter;
                tagNameFlag = true;
            }
            else if (letter == ">") {
                console.log(tagName);
                tagNameFlag = false;
                tagNameArray.push(tagName);
                tagName = "";
                unstructuredCodeArray.splice(index+1, 0, "\n\t");
                // tagName = "";
            }
            else if(unstructuredCodeArray[index-2] + unstructuredCodeArray[index-1] == "</"){
                // console.log(index)
                // unstructuredCodeArray.splice(index, 0, "\n");
            }
            
        })
        console.log(unstructuredCodeArray.join(''))
    }
    // structureCode(htmlContent)
    // structureCode(`<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><title>Document</title></head><body>hey</body></html>`)
    useEffect(() => {
        const fetchChatData = async () => {
            let res = await axios.get(process.env.REACT_APP_BACKEND_URL + '/preview/chatData', { withCredentials: true });
            if (res.data.userMessages) {
                setChatData(res.data);
                setHtmlContent(res.data.htmlCode);
            }
        }
        fetchChatData();
    }, [])

    setTimeout(() => Prism.highlightAll(), 0);


    const handleInputChange = (event) => {
        setUserInput(event.target.value);
    };

    const callAI = async (userInput) => {
        const previewLoading = document.getElementsByClassName("preview-loading")[0];
        const typingIndicator = document.getElementsByClassName("typing-indicator")[0];

        setTimeout((e) => { typingIndicator.style.display = "flex" }, 500);
        // previewLoading.style.opacity= 100;
        // setTimeout(() => {previewLoading.style.display = "flex"}, 500)

        let res = await axios.post(process.env.REACT_APP_BACKEND_URL + '/gpt/ask', {
            userInput: userInput,
            userId: userData.id
        },
            { withCredentials: true });
        const newMessage = {
            position: 'left',
            type: 'text',
            text: res.data.text,
            date: new Date(),
        };
        typingIndicator.style.display = "none";
        setMessages((prevMessages) => [...prevMessages, newMessage]);

        if (res.data.previewFlag) {
            let htmlRes = await axios.get(process.env.REACT_APP_BACKEND_URL + '/preview/desktop', { withCredentials: true });
            setHtmlContent(htmlRes.data.data);
        }
        previewLoading.style.display = "none";
    }

    const handleSendMessage = () => {
        if (!chatData.userMessages && userInput!= "") {
            const welcomeChatMsg = document.getElementsByClassName("welcome-chat-msg")[0];
            welcomeChatMsg.style.display = "none";
        }

        if (userInput.trim()) {
            const newMessage = {
                position: 'right',
                type: 'text',
                text: userInput,
                date: new Date(),
            };
            setMessages([...messages, newMessage]);
            setUserInput('');

            callAI(userInput);
        }
    };

    const handleEnter = (e) => {
        if (e.which == 13) {
            handleSendMessage();
        }
    }

    const handleCodeClick = () => {
        const codeWrapper = document.getElementsByClassName("code-wrapper")[0];
        const codeLogo = document.getElementsByClassName("code-logo")[0];
        let screenWidth = window.screen.width;

        // Change the code preview layout for mobile/tablet devices
        if (screenWidth <= 1000) {
            if (codeOpen) {
                codeWrapper.style.transform = "translateX(100%)";
                codeLogo.style.backgroundColor = "var(--lightYellow)";
                codeLogo.style.paddingRight = "10px";
                codeLogo.style.color = "black";
            }
            else {
                codeWrapper.style.transform = "translateX(0)";
                codeLogo.style.backgroundColor = "var(--darkBlue)";
                codeLogo.style.paddingRight = "5px";
                codeLogo.style.color = "white";
            }
        }
        else {
            if (!codeOpen) {
                codeWrapper.style.transform = "translateX(0)";
                codeLogo.style.backgroundColor = "var(--darkBlue)";
                codeLogo.style.color = "white";
            }
            else {
                codeWrapper.style.transform = "translateX(100%)";
                codeLogo.style.backgroundColor = "var(--lightYellow)";
                codeLogo.style.color = "black";
            }
        }
        setCodeOpen(!codeOpen);
    }

    return (
        <div className="chat-page-container">
            <div className="code-wrapper">
                <div className="code-container">
                    <div className="code-logo" onClick={handleCodeClick}>
                        <span className="material-symbols-outlined code-logo-span">
                            {codeOpen ? "close" : "code_blocks"}
                        </span>
                    </div>
                    <div className="code-preview">
                        <h2 className="code-preview-heading">code</h2>
                        <div className="Code">
                            <pre className='line-numbers'>
                                <code className='language-html'>{htmlContent}</code>
                            </pre>
                        </div>
                        <p className="code-preview-note">
                            The code preview might not look structured.
                        </p>
                    </div>
                </div>
            </div>

            <div className="user-chat-container">
                <div className="user-chat">
                    {chatData.userMessages ?
                        chatData.userMessages.map((message, index) => (
                            <>
                                <MessageBox
                                    key={index}
                                    position="right"
                                    type="text"
                                    text={message}
                                    date="none"
                                    dateString="none"
                                />
                                <MessageBox
                                    key={index+1}
                                    position="left"
                                    type="text"
                                    text={chatData.chatbotMessages[index]}
                                    date="none"
                                    dateString="none"
                                />
                            </>
                        ))
                        : <div className="welcome-chat-msg">
                            <p className="welcome-q">
                                Have a design but don't know how to code?
                            </p>
                            <p className="welcome-q-1">
                                Not to worry
                            </p>
                            <p className="welcome-q-2">
                                Ask <span className='chat-web-title'>{"</codesip>"}</span>
                            </p>
                        </div>}

                    {messages.map((message, index) => (
                        <MessageBox
                            key={index}
                            position={message.position}
                            type={message.type}
                            text={message.text}
                            date={message.date}
                            dateString={message.date.toLocaleTimeString()}
                        />
                    ))}
                    <div className="chat-con" style={{ justifyContent: "flex-start" }}>
                        <div className="chat-message-wrapper typing-indicator">
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                        </div>
                    </div>
                </div>
                <div className="message-box-container">
                    <div className="message-box">
                        <input
                            type="text"
                            name="user-input"
                            id="user-input"
                            placeholder="Write your imagination here"
                            value={userInput}
                            onChange={handleInputChange}
                            onKeyDown={handleEnter}
                        />
                        <button type="button" className="build-button" onClick={handleSendMessage}>
                            <span className="material-symbols-outlined build-button-span">
                                arrow_upward
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="website-preview-container">
                <div className="website-frame-wrapper">
                    <iframe className="website-frame" srcDoc={
                        htmlContent == "Preview" ?
                            "<div style='width:100vw; height: 100vh; display:flex; justify-content:center; align-items:center; font-style:Calibri; background-color:black;'>Live Preview of your website will appear here<div>"
                            : htmlContent}>
                    </iframe>
                    <div className="preview-loading">Loading the preview....</div>
                    {/* <div className="preview-loading-bg"></div> */}
                </div>
            </div>
        </div>
    );
}

export default Chat;