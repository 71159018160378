import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../Static/CSS/login.css';

function OTP({ signupUserData, setSignupUserData, setAuth, setUserData, addNotification }) {
  const [OTPValue, setOTPValue] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/user/signup', 
        { ...signupUserData, OTP:OTPValue},
      { withCredentials: true });

      if (response.status === 200) {
        addNotification(response.data.message || 'Account created successfully.');
        setAuth(true);
        setUserData(response.data.user);
        setSignupUserData({});
        navigate("/");
      }
      else {
        addNotification('Unable to signup', '#652222');
      }
      addNotification(response.data.message);
    } catch (error) {
      if (error.response) {
        addNotification(error.response.data.message, '#652222');
      } else if (error.request) {
        addNotification('No response from the server', '#652222');
      } else {
        addNotification('Error creating the request', '#652222');
      }
    }
    setLoading(false);
  };

  const resendOTP = async (event) => {
    event.preventDefault();
    const resendOTPpara = document.getElementsByClassName("otp-p")[0];
    resendOTPpara.style.display = "none";
    setLoading(true);

    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/user/verify', {
        name: signupUserData.name,
        email: signupUserData.email
      },
      { withCredentials: true });

      if (response.status === 200) {
        addNotification(response.data.message || 'Verification code sent.');
        
      }
      else {
        console.log(response.data.message)
        addNotification(response.data.message || 'Unable to send the code.', '#652222');
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response)
        addNotification(error.response.data.message, '#652222');
      } else if (error.request) {
        addNotification('No response from the server', '#652222');
      } else {
        addNotification('Error creating the request', '#652222');
      }
    }
    setOTPValue('');
    setLoading(false);
  };

  return (
    <div className="otp-container">
      <form onSubmit={handleSubmit} className="otp-form">
        <h2>verification</h2>
        <div className="input-group">
          <label htmlFor="otp">We've sent a verification code to your email</label>
          <input
            type="text"
            id="otp"
            required
            value={OTPValue}
            onChange={(e) => setOTPValue(e.target.value)}
            placeholder="Enter Verification Code"
          />
        </div>
        <button type="submit" disabled={loading ? true : false}>
          {loading ?
            <span className='material-symbols-outlined loading-icon'>progress_activity</span>
            : "Verify"}
        </button>
        <p className="verify-link otp-p">
          <span onClick={resendOTP} className='otp-resend-link'>Resend code</span>
        </p>
      </form>
    </div>
  );
}

export default OTP;
