import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../Static/CSS/login.css';

function Login({ setAuth, setUserData, addNotification }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/user/login', {
        email,
        password
      },
        { withCredentials: true });

      if (response.status === 200) {
        addNotification(response.data.message || 'Login successful');
        setAuth(true);
        setUserData(response.data.user);
      }
      else {
        addNotification('Unable to login', '#652222');
      }
    } catch (error) {
      if (error.response) {
        addNotification(error.response.data.message || 'Invalid login credentials', '#652222');
      } else if (error.request) {
        addNotification('No response from the server', '#652222');
      } else {
        addNotification('Error logging in', '#652222');
      }
    }
    setLoading(false);
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <h2>login</h2>
        <div className="input-group">
          {/* <label htmlFor="email">Email:</label> */}
          <input
            type="email"
            id="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder='Email'
          />
        </div>
        <div className="input-group">
          {/* <label htmlFor="password">Password:</label> */}
          <input
            type="password"
            id="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder='Password'
          />
        </div>
        <button type="submit" disabled={loading ? true : false}>
          {loading ?
            <span className='material-symbols-outlined loading-icon'>progress_activity</span>
            : "let's go"}
        </button>
        <p className="signup-link">
          New here? <Link to="/signup">Sign up</Link>
        </p>
      </form>
    </div>
  );
}

export default Login;
