import './App.css';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Home from './Components/Home';
import Chat from './Components/Chat';
import About from './Components/About';
import Contact from './Components/Contact';
import Login from './Components/Login';
import Signup from './Components/Signup';
import OTP from './Components/OTP';
import Notification from './Components/Notification';


function App() {
  const [globalMessage, setGlobalMessage] = useState('');
  const [auth, setAuth] = useState(false);
  const [userData, setUserData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [verificationPageFlag, setVerificationPageFlag] = useState(false);
  const [signupUserData, setSignupUserData] = useState({});
  const [notifications, setNotifications] = useState([]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_URL + '/user/auth', { withCredentials: true });
        if (response.status == 200) {
          setUserData(response.data.user);
          setAuth(true);
        }
        else {
          setAuth(false);
        }
      } catch (error) {
        setUserData({});
        setAuth(false);
      }
    };

    checkAuthStatus();
  }, []);

  // Add new notification
  const addNotification = (desc, color) => {
    const newNotification = { id: Date.now(), desc, color };
    setNotifications((prev) => [...prev, newNotification]);

    // Automatically remove after 5 seconds
    setTimeout(() => {
      setNotifications((prev) =>
        prev.filter((notification) => notification.id !== newNotification.id)
      );
    }, 5000);
  };

  // logout function
  const logoutButton = async (e) => {
    e.preventDefault();
    try {
      var response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/user/logout", { withCredentials: true });
      if (response.status === 200) {
        setGlobalMessage("Logout Successful.");
        setAuth(false);
        setUserData({});
      }
      else {
        setGlobalMessage(response.data.message || "Something went wrong please try again.");
      }
    } catch (error) {
      setGlobalMessage("Something went wrong.");
    }
  }


  return (
    <>
      <Router>
        <div>
          <div className="colored-bg">
            <div className="ball-con1">
              <div className="ball1"></div>
              <div className="ball2"></div>
            </div>
            <div className="ball-con2">
              <div className="ball3"></div>
              <div className="ball4"></div>
            </div>
          </div>
          <div className="blurred-bg"></div>
          <header className="header">
            <div className="container header-container">
              <Link to="/" onClick={toggleMenu}>
                <h1 className="title">
                  <p className="title-bracket-left-con">
                    <span className='title-bracket-left'>{'<'}</span>
                  </p>
                  <p className="title-bracket-left-con-ob">
                    <span className='title-bracket-left-ob'>{'/'}</span>
                  </p>
                  <p className="main-title-con">
                    <span className='main-title'>
                      {'codesip'}
                    </span>
                  </p>
                  <p className="title-bracket-right-con">
                    <span className='title-bracket-right'>{'>'}</span>
                  </p>
                </h1>
              </Link>
              <div className="hamburger-menu" onClick={toggleMenu}>
                <div className={isOpen ? "bar open" : "bar"}></div>
                <div className={isOpen ? "bar open" : "bar"}></div>
                <div className={isOpen ? "bar open" : "bar"}></div>
              </div>
              <nav className={`navigation-menu ${isOpen ? 'open' : ''}`}>
                <ul>
                  {/* <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
                <li><Link to="#about" onClick={toggleMenu}>About</Link></li>
                <li><Link to="#contact" onClick={toggleMenu}>Contact</Link></li> */}
                  <li className="profile-menu">
                    <span className="material-symbols-outlined account_logo">
                      account_circle
                    </span>
                    <div className="dropdown-content">
                      {auth ? (
                        <>
                          <div className="user-name-con">Hi, {userData.name}</div>
                          <a href="" className="logout" onClick={logoutButton}>Logout</a>
                        </>
                      ) : (
                        <>
                          <Link to="/login" onClick={toggleMenu}>Login</Link>
                          <Link to="/signup" onClick={toggleMenu}>Signup</Link>
                        </>
                      )}
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
          </header>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/chat" element={auth ? <Chat userData={userData} /> : <Navigate to="/login" />} />
            <Route path="#about" element={<About />} />
            <Route path="#contact" element={<Contact />} />
            {auth ?
              <></> :
              <>
                <Route path="/login" element={<Login setAuth={(value) => { setAuth(value) }} setUserData={(value) => setUserData(value)} addNotification={(title, desc) => addNotification(title, desc)} />} />
                <Route path="/signup" element={<Signup setVerificationPageFlag={(value) => setVerificationPageFlag(value)} setSignupUserData={(value) => setSignupUserData(value)} addNotification={(title, desc) => addNotification(title, desc)} />} />
              </>}

            <Route path='/verification'
              element={verificationPageFlag ?
                <OTP signupUserData={signupUserData} setSignupUserData={(value) => setSignupUserData(value)} setAuth={(value) => { setAuth(value) }} setUserData={(value) => setUserData(value)} addNotification={(title, desc) => addNotification(title, desc)} />
                : <Navigate to="/signup" />} />
            <Route
              path="*"
              element={<Navigate to="/" />}
            />
          </Routes>
        </div>
      </Router>

      <div className="notification-con">
        {notifications.map((notification) => {
          return <Notification desc={notification.desc} color={notification.color} />
        })}
      </div>
    </>
  );
}

export default App;
